import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay, Thumbs } from 'swiper/modules';
import 'swiper/css';

document.addEventListener('turbo:load', function () {
  var swiper = new Swiper('.main-page-cards-swiper', {
    modules: [Navigation, Autoplay],
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 30,
    speed: 500,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    }
  });

  var swiper = new Swiper('.cards-swiper', {
    modules: [Navigation, Autoplay],
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 30,
    speed: 500,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });

  var swiper = new Swiper('.delivered-swiper', {
    modules: [Autoplay],
    loop: true,
    slidesPerView: "auto",
    speed: 6500,

    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    }
  });
  var swiper = new Swiper('.delivered-reversed-swiper', {
    modules: [Autoplay],
    loop: true,
    slidesPerView: "auto",
    speed: 6500,

    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      reverseDirection: true,
    }
  });

  var swiperFollow = new Swiper('.follow-us-slider', {
    modules: [Autoplay],
    loop: true,
    slidesPerView: "auto",
    speed: 6500,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      reverseDirection: true
    },
    breakpoints: {
      501: {
        slidesPerView: 3,
      }
    }
  });

  var galleryCarSlider = new Swiper('.gallery-slider', {
    slidesPerView: "auto",
    slidesPerGroup: 1,
    spaceBetween: 16
  });

  var showCarSlider = new Swiper('.show-slider', {
    modules: [Navigation, Thumbs],
    navigation: {
      nextEl: '.swiper-button-next.show',
      prevEl: '.swiper-button-prev.show',
    },
    thumbs: {
      swiper: galleryCarSlider,
    },
  });

  var feedbackSlider = new Swiper('.feedback-slider', {
    modules: [ Autoplay, Pagination ],
    loop: true,
    slidesPerView: "auto",
    speed: 3500,
    spaceBetween: 56,
    centeredSlides: true,
    autoplay: {
      delay: 6500,
      disableOnInteraction: false,
      reverseDirection: false,
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakpoints: {
      501: {
        direction: "vertical",
        autoplay: {
          reverseDirection: true,
        }
      }
    }
  });
  var clientSlider = new Swiper('.clients-slider1', {
    modules: [ Autoplay ],
    loop: true,
    slidesPerView: "auto",
    speed: 5500,
    spaceBetween: 24,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      reverseDirection: true,
    },
    breakpoints: {
      851: {
        direction: "vertical",
      }
    }
  });
  var clientSlider2 = new Swiper('.clients-slider2', {
    modules: [ Autoplay ],
    loop: true,
    slidesPerView: "auto",
    speed: 5500,
    spaceBetween: 24,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
      reverseDirection: false,
    },
    breakpoints: {
      851: {
        direction: "vertical",
      }
    }
  });

  if (window.innerWidth < 680) {
    var deliveryGallerySwiper = new Swiper('.delivery-gallery-swiper', {
      modules: [Navigation, Autoplay],
      loop: true,
      slidesPerView: "auto",
      spaceBetween: 10,
      speed: 6500,
      autoplay: {
        delay: 0,
        disableOnInteraction: true,
      }
    });
  }

  var instockSwiper = new Swiper('.instock-swiper', {
    modules: [Navigation, Autoplay],
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 30,
    speed: 500,
    navigation: {
      nextEl: '.instock-btn-next',
      prevEl: '.instock-btn-prev',
    },
  });

  var damageSwiper = new Swiper('.damage-swiper', {
    modules: [Navigation, Autoplay],
    loop: true,
    slidesPerView: "auto",
    spaceBetween: 30,
    speed: 500,
    navigation: {
      nextEl: '.damage-btn-next',
      prevEl: '.damage-btn-prev',
    },
  });
});
