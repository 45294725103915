const Cookies = require('js-cookie')
import Rails from "@rails/ujs";
import intlTelInput from "intl-tel-input/intlTelInputWithUtils"

function getRandomHex(size) {
    const characters = '0123456789abcdef';
    let result = Array.from({ length: size }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
    return result;
}

function genetateRandomString() {
  const timeNow = Math.floor(Date.now() / 1000);
  const randomHex = getRandomHex(12);
  return `${timeNow}-${randomHex}`;
}


$(document).ready(function() {
  $('#request-form, #delivered-form, #calculator-form, #catalogue-form, #request-offer,' +
    ' #request-offer-cant-find, #request-special-offer, #leave-application-faq, #leave-application-about,' +
    ' #leave-application-contact, #leave-application-index, #leasing-form, #oferta-form,' +
    ' #another-car-form, #car-auction-form, #credit-form, #request-credit,' +
    ' #credit-car-auction-form').submit(function(event) {
    event.preventDefault();

    let form = $(this);

    let formData = form.serializeArray().filter(function(item) {
      return item.name !== 'phone';
    }).map(function(item) {
      return item.name + '=' + encodeURIComponent(item.value);
    }).join('&');

    let number = form.find('input[name="phone"]').val();
    let countryCallingCode = form.find('.iti__selected-dial-code').text();
    let full_phone = [countryCallingCode, number].join('');
    formData += '&phone=' + encodeURIComponent(full_phone);

    let { utm_source = "", utm_medium = "", utm_campaign = "", utm_content = "", utm_term = "" } = Cookies.get();

    grecaptcha.ready(function() {
      grecaptcha.execute('6LffCawpAAAAAMc0lqoFUDCGFj0AXT6AA_pJOU8z', { action: 'submit' }).then(function(token) {
        formData += '&token=' + token +
          '&utm_source=' + utm_source +
          '&utm_medium=' + utm_medium +
          '&utm_campaign=' + utm_campaign +
          '&utm_content=' + utm_content +
          '&utm_term=' + utm_term +
          '&source_url=' + window.location.href;


        // development environment - change url in localhost
        $.ajax({
          type: 'POST',
          url: 'https://mycrm.bidmotors.bg/new_request_form',
          // url: 'http://localhost:3001/new_request_form',
          crossDomain: true,
          data: formData,
          headers: {
            'accept': 'application/json',
            'Access-Control-Allow-Origin':'*'
          },
          success: function(response) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'event': 'cf7submission',
              'formId': form.attr('id'),
              'userName': form.serializeArray().find(item => item.name === 'username')?.value,
              'userEmail': form.serializeArray().find(item => item.name === 'mail')?.value,
              'userPhone': full_phone
            });

            window.location.href = '/thank-you';
            if (!sessionStorage.getItem('lead_session_request_sent')) {
              const event_fb_id = genetateRandomString();
              formData += '&event_fb_id=' + event_fb_id;

              fbq('track', 'Lead', { eventID: event_fb_id });
              // Rails.ajax({
              //   url: '/fbq_lead_generate',
              //   type: 'post',
              //   data: formData
              // });

              sessionStorage.setItem('lead_session_request_sent', true);
            }
          },
          error: function(xhr, status, error) {
            console.error('Form submission failed:', error);
            let successMessage = document.querySelector('.send-form-massage-faile');
            successMessage.style.left = "20px"
            successMessage.innerText = 'Form submission failed';
            setTimeout(function() {
              successMessage.style.left = "-500px"
            }, 5000);
          }
        });
      });
    });
  });
});
